<template>
  <div class="siglesource">
    <div v-if="loading || !siglesource">
      <b-container fluid="xxl">
        <b-row class="justify-content-md-center">
            <b-col class="mt-5">
              <div class="spinner-border m-5" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-else>
      <b-container fluid="xxl">
        <b-row>
          <b-col class="pl-0">
            <PageTitle v-bind:title="siglesource.code" :pretitle="'Sigle Source details'" />
          </b-col>
          <b-col>
            <div class="mt-5 text-right">
              <span v-if="editMode && config.editable">
                <button @click="saveEdit" class="btn-legam-xs mb-2 btn-primary">
                  <b-icon icon="lock-fill"></b-icon>
                  Save changes
                </button>
                <button @click="cancelEdit" class="btn-legam-xs mb-2">
                  Cancel
                </button>
                <button @click="deleteItem" v-if="$store.getters.checkRole('legam.bib.siglesource.delete')" class="btn-legam-xs mb-2 btn-danger">
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
              <span v-else>
                <button @click="editMode = true" v-if="$store.getters.checkRole('legam.bib.siglesource.modify')" class="btn-legam-xs mb-2">
                  <b-icon icon="unlock-fill"></b-icon>
                  Unlock editing
                </button>
                <button @click="deleteItem" v-if="!$store.getters.checkRole('legam.bib.siglesource.modify') && $store.getters.checkRole('legam.bib.siglesource.delete')" class="btn-legam-xs mb-2 btn-danger">
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
            </div>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col>
            <article>
              <b-row>
                <b-col>
                  <h6>Code</h6>
                  <b-form-input
                    id="code"
                    v-model="siglesource.code"
                    placeholder="Code"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="siglesource.code"></span>
                </b-col>
                <b-col>
                  <h6>Description</h6>
                  <b-form-textarea
                    id="description"
                    placeholder="Description"
                    v-model="siglesource.description"
                    rows="3"
                    max-rows="6"
                    v-if="editMode"
                  ></b-form-textarea>
                  <span v-else v-html="siglesource.description"></span>
                </b-col>
              </b-row>
            </article>
          </b-col>
        </b-row>
        <hr class="mt-4">

        <div class="accordion" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-1 class="text-left">
                Editions <small>({{ siglesource.sigleEditions.length }} {{ siglesource.sigleEditions.length === 1 ? 'entry' : "entries" }})</small>
                <span class="when-open float-right">-</span>
                <span class="when-closed float-right">+</span>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-1" accordion="my-accordion-1" role="tabpanel">
              <b-card-body>
                <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-edition>
                  <b-icon icon="plus"></b-icon>
                  Add
                </span>
                <b-modal id="modal-add-edition" title="Add edition" ok-only ok-variant="secondary" size="lg">
                  <EditionAutocomplete @select="addEdition" />
                </b-modal>
                <EditionTable
                  :showTotal="false"
                  :editions="siglesource.sigleEditions.map(xEdition => xEdition.edition)"
                  @delete="deleteEdition"
                  :editMode="editMode"
                  v-if="siglesource.sigleEditions.length"
                />
                <span v-else>No data</span>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-2 class="text-left">
                Historical Authors <small>({{ siglesource.sigleAuthorHists.length }} {{ siglesource.sigleAuthorHists.length === 1 ? 'entry' : "entries" }})</small>
                <span class="when-open float-right">-</span>
                <span class="when-closed float-right">+</span>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-2" accordion="my-accordion-2" role="tabpanel">
              <b-card-body>
                <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-authorhist>
                  <b-icon icon="plus"></b-icon>
                  Add
                </span>
                <b-modal id="modal-add-authorhist" title="Add author" ok-only ok-variant="secondary" size="lg">
                  <AuthorhistAutocomplete @select="addAuthorhist" />
                </b-modal>
                <AuthorhistTable
                  :showTotal="false"
                  :authorhists="siglesource.sigleAuthorHists.map(xAuthorhist => xAuthorhist.authorhist)"
                  @delete="deleteAuthorhist"
                  :editMode="editMode"
                  v-if="siglesource.sigleAuthorHists.length"
                />
                <span v-else>No data</span>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-3 class="text-left">
                Studies <small>({{ siglesource.sigleStudies.length }} {{ siglesource.sigleStudies.length === 1 ? 'entry' : "entries" }})</small>
                <span class="when-open float-right">-</span>
                <span class="when-closed float-right">+</span>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-3" accordion="my-accordion-3" role="tabpanel">
              <b-card-body>
                <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-study>
                  <b-icon icon="plus"></b-icon>
                  Add
                </span>
                <b-modal id="modal-add-study" title="Add study" ok-only ok-variant="secondary" size="lg">
                  <StudyAutocomplete @select="addStudy" />
                </b-modal>
                <StudyTable
                  :showTotal="false"
                  :studies="siglesource.sigleStudies.map(xStudy => xStudy.study)"
                  @delete="deleteStudy"
                  :editMode="editMode"
                  v-if="siglesource.sigleStudies.length"
                />
                <span v-else>No data</span>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-4 class="text-left">
                Texts <small>({{ siglesource.sigleTexts.length }} {{ siglesource.sigleTexts.length === 1 ? 'entry' : "entries" }})</small>
                <span class="when-open float-right">-</span>
                <span class="when-closed float-right">+</span>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-4" accordion="my-accordion-4" role="tabpanel">
              <b-card-body>
                <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-text>
                  <b-icon icon="plus"></b-icon>
                  Add
                </span>
                <b-modal id="modal-add-text" title="Add study" ok-only ok-variant="secondary" size="lg">
                  <TextAutocomplete @select="addText" />
                </b-modal>
                <TextTable
                  :showTotal="false"
                  :texts="siglesource.sigleTexts.map(xText => xText.text)"
                  @delete="deleteText"
                  :editMode="editMode"
                  v-if="siglesource.sigleTexts.length"
                />
                <span v-else>No data</span>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>

        <div class="mt-4">
          <h6>Logs</h6>
          <ChangeLogsTable :objectType="'siglesource'" :objectId="siglesourceCode" :key="'logs' + index" />
        </div>
      </b-container>
    </div>
  </div>
</template>

<style scoped>
  h6 {
    margin-bottom: 2px;
    margin-top: 1rem;
  }
</style>

<script>
  import { mapState } from 'vuex'
  import router from '@/router'
  import PageTitle from '@/components/PageTitle.vue'
  import ChangeLogsTable from '@/components/ChangeLogsTable.vue'
  import EditionAutocomplete from '@/components/Bibliography/EditionAutocomplete.vue'
  import StudyAutocomplete from '@/components/Bibliography/StudyAutocomplete.vue'
  import TextAutocomplete from '@/components/Bibliography/TextAutocomplete.vue'
  import EditionTable from '@/components/Bibliography/EditionTable.vue'
  import StudyTable from '@/components/Bibliography/StudyTable.vue'
  import TextTable from '@/components/Bibliography/TextTable.vue'
  import AuthorhistTable from '@/components/Bibliography/AuthorhistTable.vue'
  import AuthorhistAutocomplete from '@/components/Bibliography/AuthorhistAutocomplete.vue'
  import config from '@/config.js'

  export default {
    name: 'SigleEntityDetails',
    data(){
      return {
        siglesourceCode: this.$route.params.sigleSource,
        loading: true,
        editMode: false,
        index: 0,
        config: config,
      }
    },
    components: {
      PageTitle,
      EditionAutocomplete,
      StudyAutocomplete,
      TextAutocomplete,
      AuthorhistAutocomplete,
      ChangeLogsTable,
      EditionTable,
      StudyTable,
      AuthorhistTable,
      TextTable
    },
    watch: {
      'siglesource'() {
        this.loading = false
      }
    },
    computed: {
      ...mapState({
        siglesource: state => state.siglesource.siglesource
      })
    },
    mounted(){
      this.$store.dispatch('loadSiglesource', this.$route.params.sigleSource)
    },
    methods: {
      cancelEdit(){
        this.editMode = false
        this.$store.dispatch('loadSiglesource', this.siglesourceCode)
      },
      saveEdit(){
        this.editMode = false
        this.loading = true
        this.$store.dispatch('saveSiglesource', this.siglesource).then(() => {
          this.$store.dispatch('loadSiglesource', this.siglesourceCode)
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Changes saved`
          })
          this.index++
        })
      },
      deleteItem(){
        this.$bvModal.msgBoxConfirm('Are you sure?').then(value => {
          if (value){
            this.$store.dispatch('deleteSiglesource', this.siglesource).then(() => {
              router.push('/siglesources')
            })
          }
        })
      },
      addAuthorhist(authorhist){
        this.$bvModal.hide('modal-add-authorhist')
        this.siglesource.sigleAuthorHists.push({
          authorhistId: authorhist.id,
          sigleCode: this.siglesource.code,
          sigle: '',
          authorhist: authorhist
        })
      },
      deleteAuthorhist(authorhistId){
        this.siglesource.sigleAuthorHists = this.siglesource.sigleAuthorHists.filter(
          sigleAuthorhist => sigleAuthorhist.authorhist.id != authorhistId
        )
      },
      addEdition(edition){
        this.$bvModal.hide('modal-add-edition')
        this.siglesource.sigleEditions.push({
          editionId: edition.id,
          sigleCode: this.siglesource.code,
          sigle: '',
          edition: edition
        })
      },
      deleteEdition(editionId){
        this.siglesource.sigleEditions = this.siglesource.sigleEditions.filter(
          sigleEdition => sigleEdition.edition.id != editionId
        )
      },
      addStudy(study){
        this.$bvModal.hide('modal-add-study')
        this.siglesource.sigleStudies.push({
          studyId: study.id,
          sigleCode: this.siglesource.code,
          sigle: '',
          study: study
        })
      },
      deleteStudy(studyId){
        this.siglesource.sigleStudies = this.siglesource.sigleStudies.filter(
          sigleStudy => sigleStudy.study.id != studyId
        )
      },
      addText(text){
        this.$bvModal.hide('modal-add-text')
        this.siglesource.sigleTexts.push({
          textId: text.id,
          sigleCode: this.siglesource.code,
          sigle: '',
          text: text
        })
      },
      deleteText(textId){
        this.siglesource.sigleTexts = this.siglesource.sigleTexts.filter(
          sigleText => sigleText.text.id != textId
        )
      }
    }
  }
</script>
